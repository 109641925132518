import React from 'react';

import { IData, ILegendProps } from '../../utils/globalTypes';

const Legend = ({ data, currentDate, width, title, priceData, costPrice }: ILegendProps) => {
	return (
		<div className="legend" style={{ borderRadius: width * 0.003, padding: width * 0.007 }}>
			{data.map((d: IData, i: number) => {
				const currentValueIndex = d.items.findIndex((item) => {
					const date = new Date(item.timestamp as unknown as string);
					const hour = date.getUTCHours();
					const minutes = date.getUTCMinutes();

					return hour === currentDate.getHours() && minutes === currentDate.getMinutes();
				});
				const currentValue = d.items[currentValueIndex];

				return (
					<div
						className={'legend-lineLabel'}
						style={{
							paddingTop: width * 0.002,
							paddingBottom: width * 0.002,
							width: '100%',
						}}
						id={`legend-lineLabel-${i}`}
						key={`legend-label-${i}`}>
						<img
							src={`assets/${d.filename}.svg`}
							style={{
								opacity: currentValue && currentValue?.value > 0 ? '1' : '.5',
								color: d.stroke,
								fontSize: width * 0.007,
								width: width * 0.012,
								paddingRight: width * 0.003,
							}}
							alt={''}
						/>
						<div
							style={{
								opacity: currentValue && currentValue?.value > 0 ? '1' : '.5',
								backgroundColor: d.stroke,
								fontSize: width * 0.007,
								padding: width * 0.002,
								borderRadius: width * 0.002,
								borderTopRightRadius: d.type === 'dc' ? 0 : width * 0.002,
								borderBottomRightRadius: d.type === 'dc' ? 0 : width * 0.002,
								boxSizing: 'border-box',
								marginRight: d.type === 'dc' ? width * 0.001 : 0,
							}}>
							{/* TODO: Handle contrast automatically. */}
							<span style={{ color: d.brand?.startsWith('Renault Trucks') ? 'white' : 'unset' }}>
								{d.type === 'car' || d.type === 'dc' ? d.brand?.toUpperCase() : d.legend?.toUpperCase()}
							</span>
						</div>
						{d.legend === 'grid' && priceData && (
							<div
								style={{
									opacity: currentValue && currentValue?.value > 0 ? '1' : '.5',
									fontSize: width * 0.007,
									padding: width * 0.002,
									borderRadius: width * 0.002,
									marginLeft: width * 0.002,
									boxShadow: `inset 0px 0px 0px ${width * 0.0007}px ${d.stroke}`,
									color: 'white',
								}}>
								<span style={{ display: 'flex' }}>
									<img
										src={`assets/dash.svg`}
										style={{
											width: width * 0.007,
										}}
										alt={''}
									/>
									{Number(priceData?.items[currentValueIndex]?.value) / 1000} €/kWh
								</span>
							</div>
						)}

						{d.legend === 'grid' && costPrice && (
							<div style={{ color: 'white', width: '100%', textAlign: 'right', fontSize: width * 0.007 }}>
								€ <span style={{ fontSize: width * 0.014 }}>{`${costPrice}`.split('.')[0]}</span>
								{'.'}
								{`${costPrice}`.split('.')[1]}
							</div>
						)}

						{d.isDc && (
							<div
								style={{
									opacity: currentValue && currentValue?.value > 0 ? '1' : '.5',
									backgroundColor: d.stroke,
									fontSize: width * 0.007,
									padding: width * 0.002,
									borderRadius: width * 0.002,
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
								}}>
								<span style={{ display: 'flex' }}>
									<img
										src={`assets/dc.svg`}
										style={{
											width: width * 0.0028,
											marginRight: width * 0.0005,
										}}
										alt={''}
									/>
									DC
								</span>
							</div>
						)}
					</div>
				);
			})}
			<span
				className="legend-title"
				style={{
					fontSize: width * 0.007,
					marginBottom: width * 0.007,
				}}>
				{title}
			</span>
		</div>
	);
};

Legend.defaultProps = {
	data: [],
};

export default Legend;
