import React, { useEffect, useMemo, useState } from 'react';
import './App.css';

import { useSocketListener } from './hooks/useSocketListener';
import MultilineChart from './widgets/MultilineChart';
import CapacityChart from './widgets/CapacityChart';
import WeatherChart from './widgets/WeatherChart/WeatherChart';
import Legend from './widgets/Legend';
import { IData, IGraphData } from './utils/globalTypes';
import useDimensions from './utils/useDimensions';
import { useSocketEmitter } from './hooks/useSocketEmitter';

interface IEventTime {
	data: {
		time: number;
		group: string;
	};
}
interface ISimulationRestart {
	timestamp: string;
	group: string;
	forceResubscribe: boolean;
}

interface ISimulationReset {
	timestamp: string;
	group: string;
}

enum ServerState {
	NO_MESSAGE = 'null',
	OPTIMIZATION_FOUND = 'optimization-found',
	NO_OPTIMIZATION_FOUND = 'no-optimization-found',
}

function Dashboard({ group }: { group: string }) {
	const { emitToServer, isSocketOpen } = useSocketEmitter();

	// TODO: when query param changes, it should unsubscribe from the previous group and subscribe to the new group
	useEffect(() => {
		if (isSocketOpen) {
			emitToServer('subscribe', { group });
		}
		setIsWebSocketConnected(isSocketOpen);
	}, [isSocketOpen]);

	const [simulationId] = useSocketListener('simulation_update');
	const [optimizationNotFound] = useSocketListener('OptimizationNotFound');
	const [serviceUnavailable] = useSocketListener('service_unavailable');
	const [serverDate] = useSocketListener('set_time');
	const [simulationReset] = useSocketListener('SimulationReset');
	const [simulationRestart] = useSocketListener('SimulationRestart');
	const [data, setData] = useState<any>([]);
	const [serverState, setServerState] = useState<ServerState>(ServerState.NO_MESSAGE);
	const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
	const [progress, setProgress] = useState<number>(25);
	const [error, setError] = useState(false);
	const [currentDate, setCurrentDate] = useState<Date>(new Date(2022, 12, 27, 8, 0, 0));
	const [containerRef, { svgWidth: width }] = useDimensions({});

	useEffect(() => {
		if (!serverDate) {
			return;
		}
		const newDate = new Date(currentDate.setHours((serverDate as IEventTime).data.time));

		setCurrentDate(newDate);
	}, [serverDate]);

	const multilineTypes = ['battery', 'car', 'load', 'dc'];

	const multilineData = useMemo(() => data.filter((d: IData) => multilineTypes.includes(d.type)), [data]);
	const solarData = useMemo(() => data.find((d: IData) => d.type === 'solar'), [data]);
	const shouldShowSolarLegend = solarData && solarData.items.some((item: IGraphData) => item.value > 0);
	const gridData = useMemo(() => data.find((d: IData) => d.type === 'grid'), [data]);
	const priceData = useMemo(() => data.find((d: IData) => d.type === 'price'), [data]);
	const priceDataValues = priceData && priceData.items.map((item: IGraphData) => item.value);
	const batteryData = useMemo(() => data.find((d: IData) => d.type === 'battery'), [data]);
	const batteryChargeData = batteryData && {
		...batteryData,
		items: batteryData.items.map((item: IGraphData) => ({
			...item,
			value: item.type === 'charge' ? Math.abs(item.value as number) : 0,
		})),
	};

	const batteryDischargeData = batteryData && {
		...batteryData,
		items: batteryData.items.map((item: IGraphData) => ({
			...item,
			value: item.type === 'discharge' ? parseInt(item.value as string) : 0,
		})),
	};
	const maxPrice = Math.max.apply(null, priceDataValues);
	const minPrice = Math.min.apply(null, priceDataValues);
	const priceDataFixed = maxPrice === minPrice;
	const weatherData = useMemo(() => data.find((d: IData) => d.type === 'weather'), [data]);
	const weatherChartHeight = window.innerHeight * 0.15;
	const capacityChartHeight = window.innerHeight * 0.15;
	const multilineChartHeight = window.innerHeight * 0.5;
	const notchHeight = window.innerHeight * 0.05;

	useEffect(() => {
		if ((simulationReset as ISimulationReset)?.group) {
			setData([]);
		}
	}, [simulationReset]);

	useEffect(() => {
		if ((simulationRestart as ISimulationRestart)?.group) {
			emitToServer('unsubscribe', { group });
			setData([]);

			if ((simulationRestart as ISimulationRestart)?.forceResubscribe) {
				emitToServer('subscribe', { group });
			}
		}
	}, [simulationRestart]);

	useEffect(() => {
		if (simulationId) {
			fetchData(group);
		}
	}, [simulationId]);

	useEffect(() => {
		if (!optimizationNotFound) {
			return;
		}

		setServerState(ServerState.NO_OPTIMIZATION_FOUND);
	}, [optimizationNotFound]);

	useEffect(() => {
		/**
		 * Will return an object with a status code to indicate Energyville is not responding
		 * If needed, a copy property can be added to allow for different copy options depending on the case
		 * On error: { "status": 503, "message": "Service Unavailable" }
		 * On ok: null
		 * TODO: Should show an error screen with instructions about what needs to happen
		 */

		setError(serviceUnavailable ? true : false);
	}, [serviceUnavailable]);

	useEffect(() => {
		fetchData(group);

		window.onbeforeunload = () => {
			emitToServer('unsubscribe', { group });
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		const startTime = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			7,
			0,
			0,
		).getTime();
		const endTime = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			23,
			0,
			0,
		).getTime();

		setProgress(((currentDate.getTime() - startTime) / (endTime - startTime)) * 100);
	});

	const fetchData = (group: string) => {
		fetch(`/api/v1/simulation?group=${group}`)
			.then((response) => response.json())
			.then((newData) => {
				if (newData.length) {
					setServerState(ServerState.OPTIMIZATION_FOUND);
				}

				const dataToDisplay = newData.map((d: IData) => {
					return {
						...d,
						items: [
							{ value: 0, timestamp: new Date(2022, 12, 27, 7, 0, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 7, 15, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 7, 30, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 7, 45, 0) },
							...d.items.slice(0, 57),
							{ value: 0, timestamp: new Date(2022, 12, 27, 22, 15, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 22, 30, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 22, 45, 0) },
							{ value: 0, timestamp: new Date(2022, 12, 27, 23, 0, 0) },
						],
					};
				});

				setData(dataToDisplay);
			});
	};

	return (
		<>
			{data && data.length && !error && (
				<div className="notch" style={{ maxHeight: notchHeight }}>
					<img className="logo" src="assets/mint-light.png" />
				</div>
			)}
			<div className="app-container" ref={containerRef}>
				{data && data.length && !error ? (
					<>
						<div className="chart">
							{multilineData && multilineData.length && (
								<MultilineChart
									data={[
										...multilineData,
										priceDataFixed ? null : priceData,
										...(batteryChargeData ? [batteryChargeData] : []),
									]}
									currentDate={currentDate}
									progress={progress}
									maxHeight={multilineChartHeight}
								/>
							)}
							{weatherData && (
								<WeatherChart
									data={weatherData}
									currentDate={currentDate}
									progress={progress}
									maxHeight={weatherChartHeight}
								/>
							)}
							{gridData && solarData && (
								<CapacityChart
									gridData={gridData}
									solarData={solarData}
									batteryDischargeData={batteryDischargeData}
									currentDate={currentDate}
									progress={progress}
									addedLinesBetween={3}
									maxHeight={capacityChartHeight}
								/>
							)}
						</div>
						<div className="legends-container">
							<div>
								<Legend
									title="CONSUMERS"
									data={multilineData}
									currentDate={currentDate}
									width={width}
								/>
								{/* <br />
								<Legend
									title="COST ESTIMATE, END OF DAY"
									data={[gridData]}
									currentDate={currentDate}
									costPrice={42.99}
									width={width}
								/> */}
							</div>

							<Legend
								title="PROVIDERS"
								data={[
									gridData,
									...(shouldShowSolarLegend ? [solarData] : []),
									...(batteryData ? [batteryData] : []),
								]}
								priceData={priceData}
								currentDate={currentDate}
								width={width}
							/>
						</div>
					</>
				) : (
					<div className="placeholder">
						<div className="logo-container">
							<img className="logo" src="assets/pxc.svg" />
						</div>
						<p className="placeholder-text">
							{error ? 'SOMETHING WENT WRONG' : 'PLACE A CAR TO RUN THE SIMULATION'}
						</p>
					</div>
				)}
			</div>
			<div className={`status status--${serverState}`}></div>
			<div
				className={`status status--${isWebSocketConnected ? 'optimization-found' : 'no-message'}`}
				style={{ right: '30px' }}></div>
		</>
	);
}

export default Dashboard;
