import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { Border, CurrentSituationLine, CurrentSituationLineGradient, XAxisGradient } from '../../components';
import useDimensions from '../../utils/useDimensions';
import { BORDER_TYPE, IData, IGraphData } from '../../utils/globalTypes';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import { renderCapacityChartGradient } from './capacityChartGradient';
import { ICapacityChartProps } from './CapacityChartController.types';

const CapacityChart = ({
	gridData,
	solarData,
	batteryDischargeData,
	progress,
	currentDate,
	margin = {},
	maxHeight = 150,
	addedLinesBetween = 0,
}: ICapacityChartProps) => {
	const svgRef = React.useRef<any>(null);
	const [processedGridData, setProcessedGridData] = React.useState<IData | null>(null);
	const [processedSolarData, setProcessedSolarData] = React.useState<IData | null>(null);
	const [processedBatteryDischargeData, setProcessedBatteryDischargeData] = React.useState<IData | null>(null);

	const [containerRef, { svgWidth, width, height }] = useDimensions({
		maxHeight,
		margin,
	});
	const { height: windowHeight } = useWindowDimensions();

	const padding = 25;
	const svg = d3.select('#capacityChart');
	const actualHeight = maxHeight;

	if (!gridData || !solarData) {
		return <></>;
	}

	useEffect(() => {
		if (width <= 0) {
			return;
		}
		// if we don't need to add extra lines in between
		if (addedLinesBetween === 0) {
			setProcessedGridData(gridData);
			setProcessedSolarData(solarData);
			return;
		}

		const gridItems: IGraphData[] = [];
		const solarItems: IGraphData[] = [];
		const batteryDischargeItems: IGraphData[] = [];

		// else
		gridData.items.forEach((d: IGraphData, index: number) => {
			const nextIndex = gridData.items[index + 1];
			const nextValue = nextIndex ? (nextIndex.value as number) : null;
			// push current item
			gridItems.push(d);

			if (typeof nextValue === 'number') {
				// for each line that we want to add
				for (let i = 0; i < addedLinesBetween; i++) {
					// when there is no difference between current and next value
					// push current value
					gridItems.push(d);
				}
			}
		});

		solarData.items.forEach((d: IGraphData, index: number) => {
			const nextIndex = gridData.items[index + 1];
			const nextValue = nextIndex ? (nextIndex.value as number) : null;
			// push current item
			solarItems.push(d);

			if (typeof nextValue === 'number') {
				// for each line that we want to add
				for (let i = 0; i < addedLinesBetween; i++) {
					// when there is no difference between current and next value
					// push current value
					solarItems.push(d);
				}
			}
		});

		batteryDischargeData?.items.forEach((d: IGraphData, index: number) => {
			const nextIndex = gridData.items[index + 1];
			const nextValue = nextIndex ? (nextIndex.value as number) : null;
			// push current item
			batteryDischargeItems.push(d);

			if (typeof nextValue === 'number') {
				// for each line that we want to add
				for (let i = 0; i < addedLinesBetween; i++) {
					// when there is no difference between current and next value
					// push current value
					batteryDischargeItems.push(d);
				}
			}
		});

		setProcessedGridData({ ...gridData, items: gridItems });
		setProcessedSolarData({ ...solarData, items: solarItems });

		if (batteryDischargeData) {
			setProcessedBatteryDischargeData({ ...batteryDischargeData, items: batteryDischargeItems });
		} else {
			setProcessedBatteryDischargeData(null);
		}
	}, [gridData, solarData, batteryDischargeData, width, windowHeight]);

	useEffect(() => {
		if (!processedGridData || !processedSolarData || width <= 0) {
			return;
		}

		// X axis
		const x = d3
			.scaleBand()
			.range([0, width])
			// @ts-ignore
			.domain(processedGridData.items.map((d, i) => i))
			.round(false)
			.padding(1);

		// Y axis
		const y = d3.scaleLinear().domain([-70, 70]).range([actualHeight, 0]);

		// Bars
		svg.selectAll('.capacity-bar-grid').remove();
		svg.selectAll('.capacity-bar-solar').remove();
		svg.selectAll('.capacity-bar-battery').remove();

		svg.selectAll('.capacity-bar-grid')
			.data(processedGridData.items)
			.enter()
			.append('rect')
			.attr('class', 'capacity-bar-grid')
			// @ts-ignore
			.attr('x', (d, i) => x(i))
			.attr('y', (d, i) => {
				// @ts-ignore
				return y(d.value + processedGridData.items[i].value);
			})
			.attr('width', 2)
			.attr('rx', 1)
			.attr('ry', 1)
			.attr('fill', 'url(#capacityChartGridGradient)');

		svg.selectAll('.capacity-bar-solar')
			.data(processedSolarData.items)
			.enter()
			.append('rect')
			.attr('class', 'capacity-bar-solar')
			// @ts-ignore
			.attr('x', (d, i) => x(i))
			.attr('y', (d, i) => {
				// @ts-ignore
				return y(d.value + processedGridData.items[i]?.value);
			})
			.attr('width', 2)
			.attr('height', function (d) {
				return actualHeight - y(d.value as number);
			})
			.attr('rx', 1)
			.attr('ry', 1)
			.attr('fill', 'url(#capacityChartSolarGradient)');

		if (processedBatteryDischargeData) {
			svg.selectAll('.capacity-bar-battery')
				.data(processedBatteryDischargeData.items)
				.enter()
				.append('rect')
				.attr('class', 'capacity-bar-battery')
				// @ts-ignore
				.attr('x', (d, i) => x(i))
				.attr('y', (d, i) => {
					// @ts-ignore
					return y(d.value + processedGridData.items[i].value);
				})
				.attr('width', 2)
				.attr('rx', 1)
				.attr('ry', 1)
				.attr('fill', 'url(#capacityChartGridGradient)');
		}

		// Add Y axis
		const yAxis = d3
			.axisRight(y)
			.ticks(5)
			.tickValues([-70, -40, 0, 40, 70])
			.tickFormat((d) => d + ' kW');
		svg.select('.y-axis-group').remove();

		// Append the new yAxisGroup
		const yAxisGroup = svg.append('g').attr('class', 'y-axis-group').call(yAxis).lower();

		yAxisGroup
			.selectAll('text')
			.each(function (d, i) {
				if (i === 4) {
					return d3.select(this).attr('transform', `translate(${width * 0.008},${width * 0.006})`);
				}

				if (i === 3) {
					return d3.select(this).attr('transform', `translate(${width * 0.008},${width * 0.004})`);
				}

				if (i === 1) {
					return d3.select(this).attr('transform', `translate(${width * 0.008},-${width * 0.004})`);
				}

				if (i === 0) {
					return d3.select(this).attr('transform', `translate(${width * 0.008},-${width * 0.006})`);
				}

				d3.select(this).attr('transform', `translate(${width * 0.008},0)`);
			})
			.attr('color', '#727272')
			.attr('font-size', width * 0.008);

		yAxisGroup
			.selectAll('line')
			.each(function (d, i) {
				if (i === 2 || i === 0) {
					d3.select(this).attr('display', `none`);
				}
			})
			.style('stroke-width', '.1%')
			.attr('stroke', 'url(#xAxisGradient-capacity)')
			.style('stroke-dasharray', '3, 3')
			.attr('x1', 0)
			.attr('x2', width);

		//Update all rects
		svg.selectAll('.capacity-bar-grid')
			.data(processedGridData.items)
			.attr('y', (d) => {
				if (d.value < 0) {
					return y(0);
				}

				if (d.value === 0) {
					return actualHeight;
				}

				return y(d.value as number);
			})
			.attr('height', function (d) {
				if (d.value < 0) {
					return actualHeight / 2 - y(Math.abs(d.value as number));
				}

				return actualHeight / 2 - y(d.value as number);
			})
			.attr('fill', 'url(#capacityChartGridGradient)');

		//Update all rects
		svg.selectAll('.capacity-bar-solar')
			.data(processedSolarData.items)
			.attr('y', (d, i) => {
				if ((processedGridData?.items[i]?.value as number) < 0) {
					return y(d.value as number);
				}
				// @ts-ignore
				return y(d.value + processedGridData?.items[i]?.value);
			})
			.attr('height', function (d, i) {
				return actualHeight / 2 - y(d.value as number);
			})
			.attr('fill', 'url(#capacityChartSolarGradient)');

		if (processedBatteryDischargeData) {
			svg.selectAll('.capacity-bar-battery')
				.data(processedBatteryDischargeData.items)
				.attr('y', (d, i) => {
					if (d.value > 0) {
						return y(0);
					}

					if (d.value === 0) {
						return actualHeight;
					}
					// @ts-ignore
					return y(Math.abs(d.value as number) + processedSolarData?.items[i]?.value);
				})
				.attr('height', function (d) {
					if (d.value < 0) {
						return actualHeight / 2 - y(Math.abs(d.value as number));
					}

					return actualHeight / 2 - y(d.value as number);
				})
				.attr('fill', 'url(#capacityChartBatteryGradient)');
		}

		renderCapacityChartGradient({ svgRef, color: gridData.stroke, id: 'capacityChartGridGradient' });
		renderCapacityChartGradient({ svgRef, color: solarData.stroke, id: 'capacityChartSolarGradient' });

		if (batteryDischargeData && processedBatteryDischargeData) {
			renderCapacityChartGradient({
				svgRef,
				color: batteryDischargeData.stroke,
				id: 'capacityChartBatteryGradient',
			});
		}
	}, [processedGridData, processedSolarData, processedBatteryDischargeData, width, windowHeight]);

	return (
		<div className="chartContainer" ref={containerRef} style={{ height: height }}>
			<svg id="capacityChart" ref={svgRef} width={svgWidth} height={height}>
				<XAxisGradient color="#3D3D3D" type="capacity" />
				<CurrentSituationLine
					width={svgWidth}
					padding={padding}
					height={height}
					progress={progress}
					type={'capacity'}
				/>
				<CurrentSituationLineGradient color={'white'} type="capacity" />
				<Border
					width={svgWidth}
					height={height}
					type={BORDER_TYPE.top}
					color={'url(#xAxisGradient-capacity)'}
				/>
				<Border
					width={svgWidth}
					height={height}
					type={BORDER_TYPE.bottom}
					color={'url(#xAxisGradient-capacity)'}
				/>
			</svg>
		</div>
	);
};

export default CapacityChart;
