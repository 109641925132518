import * as d3 from 'd3';
import React from 'react';

export const renderCapacityChartGradient = ({ svgRef, color, id }: ICapacityChartGradientProps) => {
	// Create root container where we will append all other chart elements
	const svgEl = d3.select(svgRef.current);

	//make defs and add the linear gradient
	const capacityChartGradient = svgEl
		.append('defs')
		.append('linearGradient')
		.attr('id', id) //id of the gradient
		.attr('x1', '0%')
		.attr('x2', '0%')
		.attr('y1', '0%')
		.attr('y2', '100%');
	capacityChartGradient.append('stop').attr('offset', '0%').style('stop-color', color).style('stop-opacity', 0.7);
	capacityChartGradient.append('stop').attr('offset', '100%').style('stop-color', color).style('stop-opacity', 0.7);
};

interface ICapacityChartGradientProps {
	svgRef: React.MutableRefObject<null>;
	color: string;
	id: string;
}
