import { useMemo } from 'react';
import * as d3 from 'd3';

import { MultilineChartControllerProps } from './MultilineChart.types';

const useController = ({ data, width, height }: MultilineChartControllerProps) => {
	const xMin = 0;
	const xMax = 100; // data length

	if (!data) {
		// I'm sorry, but this is because of the following error:
		// Warning: React has detected a change in the order of Hooks called by MultilineChart. This will lead to bugs and errors if not fixed. For more information, read the Rules of Hooks: https://reactjs.org/link/rules-of-hooks
		// 	Previous render            Next render
		// 	------------------------------------------------------
		//  1. useRef                     useRef
		//  2. useRef                     useRef
		//  3. useState                   useState
		//  4. useEffect                  useEffect
		//  5. useMemo                    useMemo
		//  6. useMemo                    useMemo
		//  7. useMemo                    useMemo
		//  8. useMemo                    useMemo
		//  9. undefined                  useMemo
		// 	^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

		useMemo(() => null, [xMin, xMax, width]);
		useMemo(() => null, [data]);
		useMemo(() => null, [data]);
		useMemo(() => null, [height, null, null]);

		return {
			xScale: null,
			yScale: null,
		};
	}

	const xScale = useMemo(() => d3.scaleLinear().domain([xMin, xMax]).range([0, width]), [xMin, xMax, width]);

	const yMin = useMemo(() => d3.min(data, ({ items }) => d3.min(items, ({ value }) => value)), [data]);

	const yMax = useMemo(() => d3.max(data, ({ items }) => d3.max(items, ({ value }) => value)), [data]);

	const yScale = useMemo(() => {
		return d3
			.scaleLinear()
			.domain([yMin, yMax])
			.range([height - 0, 1]);
	}, [height, yMin, yMax]);

	return {
		xScale,
		yScale,
	};
};

export default useController;
