import { useContext, useEffect, useState } from 'react';

import { ConfigContext } from '../providers/ConfigProvider';

import { eventEmitter } from './useEventEmitter';

const useSocketEmitter = () => {
	const [isSocketOpen, setIsSocketOpen] = useState(false);
	const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

	const { API_WSS } = useContext(ConfigContext);

	const maxRetries = 10;
	const disabled = !!process.env.REACT_APP_DISABLE_SOCKET;
	let socketInterval: NodeJS.Timer | null = null;
	let retries = 0;

	useEffect(() => {
		if (disabled || !API_WSS) {
			return;
		}

		connect();
		startConnectionInterval();
	}, [API_WSS]);

	const startConnectionInterval = () => {
		if (socketInterval) {
			clearInterval(socketInterval);
			socketInterval = null;
		}

		socketInterval = setInterval(() => {
			if (retries >= maxRetries) {
				socketInterval && clearInterval(socketInterval);
				return;
			}

			if (!isSocketOpen) {
				reconnect();
				retries = retries + 1;
			}
		}, 10000);
	};

	const connect = async (): Promise<boolean> => {
		if (!API_WSS) {
			return false;
		}

		const wsUrl = API_WSS;
		const ws = new WebSocket(wsUrl);
		setWebSocket(ws);

		return new Promise((resolve, reject) => {
			ws.addEventListener('open', () => {
				console.log('ws connected');

				setIsSocketOpen(true);
				socketInterval && clearInterval(socketInterval);
				retries = 0;

				resolve(true);
			});

			ws.addEventListener('message', (eventData) => {
				try {
					const { event, data } = JSON.parse(eventData.data);
					eventEmitter.emit(event, data);
				} catch (e) {
					console.error('Failed parsing websocket event', e);
				}
			});

			ws.addEventListener('close', () => {
				console.log('ws disconnected');

				setIsSocketOpen(false);
				startConnectionInterval();

				reject(false);
			});
		});
	};

	const reconnect = async () => {
		try {
			await connect();
		} catch (err) {
			console.log('ws could not reconnect');
		}
	};

	const emitToServer = (event: string, data: any) => {
		if (!disabled) {
			const message = JSON.stringify({ event, data });
			if (webSocket !== null) {
				webSocket.send(message);
			}
		}
	};

	return { emitToServer, isSocketOpen };
};

export { useSocketEmitter };
