import { useState } from 'react';

import { eventEmitter } from './useEventEmitter';

const useSocketListener = (event: string): unknown[] => {
	const [data, setData] = useState(null);

	eventEmitter.on(event, (eventData: any) => {
		return setData(eventData);
	});

	return [data];
};

export { useSocketListener };
